import { SwapFeaturesProvider } from '../views/Swap/SwapFeaturesContext'
import { SUPPORT_SWAP } from '../config/constants/supportChains'
import dynamic from 'next/dynamic'

const Swap = dynamic(() => import('views/Swap'), { ssr: false })

const SwapPage = () => {
  return (
    <SwapFeaturesProvider>
      <Swap />
    </SwapFeaturesProvider>
  )
}

SwapPage.chains = SUPPORT_SWAP

export default SwapPage
